
import { createRouter, createWebHashHistory } from "vue-router";


const routes = [
    { 
        path: '/', 
        component: () => import('@/pages/HojaTecnicaPage.vue'),
    },
    { 
        path: '/:pathMatch(.*)*', 
        component: () => import('@/pages/NoPageFound.vue'),
    },
    { 
        path: '/pdf', 
        component: () => import('@/pages/PDFDownload.vue'),
    },
    
]

const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
  })

export default router
  
