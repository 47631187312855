export default {
  methods: {
    unificarTexto(arr){
      let texto = ""
      for (let index = 0; index < arr.length; index++) {
        let cleanText = arr[index].Texto.replace("##", "<")
        cleanText = cleanText.replace("$$", ">")
        texto += cleanText
      }
      return texto
    },
  },
}