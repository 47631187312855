<template>

  <router-view />

</template>

<script>

export default {
  name: 'App',
  components: {
  },
  created () {
    document.title = "Hojas Técnicas Omar Coatings";
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10fr;
  margin-bottom: 30px;
}
</style>
